<div class="sidebar sidebar--large sidebar--right" [class.opened]="opened">
    <div class="px-4 py-3">
        <div class="row py-4">
            <div class="col text-left">
                <h6 class="color-primary f-subtitle">Bitácora</h6>
            </div>
            <div class="col"> <span class="d-flex justify-content-center badge badge-pill badge-primary rounded-circle f-subtitle">{{countComment}}</span>
            </div>
            <div class="col-2 p-0 m-0">
                <button class="btn btn-light btn-icon rounded translate-end" (click)="close()">
                    <i class="las la-times"></i>
                </button>
            </div>

        </div>

        <form [formGroup]="form" class="inn-form" (ngSubmit)="save($event)" novalidate>
            <div class="form-outline">
                <small class="color-primary f-subtitle">Ingresa un comentario</small>
                <textarea class="form-control bg-form_input mt-2" id="textAreaExample1" rows="4" placeholder="Máximo 300 caracteres" minlength="5" maxlength="300" formControlName="comments"></textarea>
            </div>
            <div class="col">
                <app-validation-error [form]="form" [field]="'comments'"></app-validation-error>
            </div>

            <div class="d-flex justify-content-around align-items-center pt-4 input-group">
                <div class="custom-file pr-3">
                    <input type="file" class="custom-file-input " id="upload-file" (change)="getAttachmentImage($event)" aria-describedby="inputGroupFileAddon01" formControlName="file">
                    <a class="btn btn-light d-flex align-items-center f-subtitle" (click)="openInputFile('upload-file')">
                        <i class="las la-paperclip pr-2">
                        </i> Adjuntar
                    </a>

                </div>
                <div class="">
                    <!-- <a type="submit" class="btn-send btn btn-light d-flex align-items-center position-relative font-weight-bold border-0" title="Enviar">
                        Enviar <i class="lab la-telegram-plane fs-normal ml-2"></i>
                    </a> -->
                    <button type="submit" class="btn-send btn btn-light position-relative d-flex align-items-center position-relative f-subtitle border-0">
                        Enviar
                        <i class="lab la-telegram-plane fs-normal ml-2 "></i>
                    </button>
                </div>
            </div>
            <div class="col">
                <app-validation-error [form]="form" [field]="'file'"></app-validation-error>
            </div>
        </form>
    </div>
    <div class="container-card" *ngFor="let maintenance of maintenances;">
        <div class="card rounded-0 card-item-add">
            <div class="card-body">
                <div class="row d-flex m-0 p-0 justify-content-between ">
                    <h5 class="card-title card_title-bitacora">{{maintenance.user.name}} </h5><label class="color-gray3">{{maintenance.updated_at | date: 'shortDate' }}</label>
                </div>
                <p class="card-text card_text-bitacora color-gray3">{{maintenance.comments}}</p>
                <div class="d-flex justify-content-start pt-4 pointer text-secondary bitacora-icons">

                    <!-- <i class="las la-file-pdf fs-normal ml-2" href="{{maintenance.url_file}}" target="_black"></i> -->
                    <a href="{{maintenance.url_file}}" target="_black" class="color-gray3 border-0 text-decoration-none" *ngIf="maintenance.url_file">
                        <i class="las la-file-pdf fs-normal mr-2 color-gray2"></i>
                    </a>

                    <i class="las la-edit fs-normal ml-0 mr-2 color-gray2" (click)="showComment(maintenance.id)"></i>

                    <i class="las la-trash-alt fs-normal mx-2 color-gray2" (click)="confirmDelete(maintenance.id)"></i>

                </div>
            </div>
        </div>
    </div>


</div>

<app-modal-confirm #modalConfirm [content]="confirmMessage" (onCloseModal)="onCloseModalConfirm($event)"></app-modal-confirm>
