<div class="bg-error-found">
    <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-8 m-auto content">
            <div class="col-10 d-block m-auto content-info">
                <h1 class="color-white p-semibold mb-2">404</h1>
                <h2 class="color-white mb-4">Página no encontrada</h2>
                <h6 class="color-white mb-4">Parece que estás intentando acceder a un sitio desconocido o que se encuentra roto</h6>
                <button class="btn btn-error">Ir a un sitio seguro</button>
            </div>
        </div>
    </div>
</div>