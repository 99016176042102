import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// -- Plugins
import {TranslateModule} from '@ngx-translate/core';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {Error500Component} from './components/error500/error500.component';
import {Error404Component} from './components/error404/error404.component';
import {ModalConfirmComponent} from './components/modal-confirm/modal-confirm.component';
import {ModalMessageComponent} from './components/modal-message/modal-message.component';
import {ValidationErrorComponent} from './components/validation-error/validation-error.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { NgxUploaderModule } from 'ngx-uploader';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MapComponent } from './components/map/map.component'
import { MapDisplayComponent } from './components/mapDisplay/map.display.component'
import { AgmCoreModule } from '@agm/core';
import { LandingComponent } from './components/landing/landing.component';
import { DetailDeviceComponent } from './components/detail-device/detail-device.component';
import { ModalBitacoraComponent } from './components/modal-bitacora/modal-bitacora.component';
import { ReportComponent } from './components/report/report.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { InfoComponent } from './components/info/info.component';
import { ModalCommentComponent } from './components/modal-comment/modal-comment.component';
import { FooterComponent } from './components/landing/footer/footer.component';
import { DrawerHeaderComponent } from './components/landing/drawer-header/drawer-header.component';
import { PartnersComponent } from './components/landing/partners/partners.component';
import { ParticleComponent } from './components/landing/particle/particle.component';
import { AirScaleComponent } from './components/landing/air-scale/air-scale.component';
 defineLocale('es', esLocale);


@NgModule(
  {
  declarations: [
    Error500Component,
    Error404Component,
    ModalConfirmComponent,
    ModalMessageComponent,
    ValidationErrorComponent,
    MapComponent,
    MapDisplayComponent,
    LandingComponent,
    DetailDeviceComponent,
    ModalBitacoraComponent,
    ReportComponent,
    InfoComponent,
    ModalCommentComponent,
    FooterComponent,
    DrawerHeaderComponent,
    PartnersComponent,
    ParticleComponent,
    AirScaleComponent,
  ],
    imports: [
      CommonModule,
      TranslateModule,
      CKEditorModule,
      NgxUploaderModule,
      FormsModule,
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyCrxe4yirskGPWA1SeFIijsUa9vheHvGwI',
      }),
      ReactiveFormsModule,
      CarouselModule,
      BsDatepickerModule.forRoot(),
      NgxPageScrollCoreModule.forRoot({duration: 500})
    ],
  exports: [
    TranslateModule,
    CKEditorModule,
    Error500Component,
    Error404Component,
    ModalConfirmComponent,
    ModalMessageComponent,
    ValidationErrorComponent,
    NgxDatatableModule,
    NgxUploaderModule,
    FormsModule,
    MapComponent,
    MapDisplayComponent,
    AgmCoreModule,
    DetailDeviceComponent,
    ModalBitacoraComponent,
    ReportComponent,
    InfoComponent
  ]
})
export class SharedModule {
}
