<div class="info">
    <div class="row align-items-center">
        <div class="col-lg-9 d-none d-md-block">
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center b-right mr-4 pr-4">
                    <i class="las la-map-marked color-primary fs-big mr-3"></i>
                    <p class="f-subtitle color-primary fs-normal nowrap">Mapa</p>
                </div>
                <p class="color-gray3 x-small">Aquí visualizas todas las estaciones de monitoreo.</p>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-sm-7">
        <div class="card">
            <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom" [mapTypeId]="'roadmap'">
                <agm-marker id="makers" *ngFor="let marker of markers;" [latitude]="marker.lat" [longitude]="marker.lon" style="border-radius: 50%;" [iconUrl]='{"url": marker.capture_log.icono,"scaledSize": {"height": 40, "width": 40}}'  (markerClick)="markerClicked(marker.device_id)">
                </agm-marker>
            </agm-map>
        </div>
    </div>
     <!-- *ngIf="!visibilidad" -->
    <div class="container card medidor p-0 m-0 col-sm-5 col-12" [ngStyle]="{'background-color': capture_log.color }" *ngIf="visibilidad">
        <div class="row p-5 m-0 container-medidor" >
            <div class="col-9 col-md-9 p-0">
                <div class="container-fluid p-0">
                    <div class="row m-0 p-0">
                        <div class="d-flex flex-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                            <div class="cloud_map d-flex position-relative align-items-center justify-content-center mr-3 mb-3 mb-sm-0">
                                <div class="text_cloud">
                                    <p class="semibold">{{capture_log.ica | number : '1.0-0'}}</p>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="text_aqui_tittle">
                                    <p class="semibold">AQI</p>
                                </div>
                                <!-- <div class="text_aqui_sub_tittle">
                                    <p class="semibold">Indice de Calidad de Aire <br> </p>
                                    {{capture_log.ica | number : '1.0-0'}} *** de 100 *** puntos
                                </div> -->
                            </div>
                        </div>

                    </div>
                    <div class="d-flex flex-column pt-4">
                        <div class="col text_quality_tittle pb-4 pt-5 pb-sm-0 pt-sm-0">
                            <p class="semibold">La calidad del aire en<br> {{capture_log.name_device}} es</p>
                        </div>
                        <div class="col text_quality_face pb-3">
                            <p class="semibold">{{capture_log.name}}</p>
                        </div>
                        <div class="col p_description">
                           <p class="semibold">{{capture_log.rangos}} {{capture_log.description}}</p>
                        </div>
                        <div class="col p_description">
                            <p class="semibold">Hora: {{capture_log.created_at}}</p>
                        </div>
                        <div class="col pb-3">
                            <p class="semibold">Promedios PM 2.5</p>
                            <table style="border: 1px solid black;border-collapse: collapse;">
                                <thead>
                                    <tr>
                                        <th style="border: 1px solid black;border-collapse: collapse;">10 Min</th>
                                        <!-- 
                                        <th style="border: 1px solid black;border-collapse: collapse;">30 Min</th> -->
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Hora</th>
                                        <!-- <th style="border: 1px solid black;border-collapse: collapse;">6 Hora</th> -->
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Día</th>
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Semana</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_uno_pm2_5}}</td>
                                        <!-- <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_dos_pm2_5}}</td> -->
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_tres_pm2_5}}</td>
                                        <!-- <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_cuatro_pm2_5}}</td> -->
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_cinco_pm2_5}}</td>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_seis_pm2_5}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col">
                            <p class="semibold">Promedios AQI</p>
                            <table style="border: 1px solid black;border-collapse: collapse;">
                                <thead>
                                    <tr>
                                        <th style="border: 1px solid black;border-collapse: collapse;">10 Min</th>
                                        <!-- 
                                        <th style="border: 1px solid black;border-collapse: collapse;">30 Min</th> -->
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Hora</th>
                                        <!-- <th style="border: 1px solid black;border-collapse: collapse;">6 Hora</th> -->
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Día</th>
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Semana</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_uno_aqi}}</td>
                                        <!-- <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_dos_aqi}}</td> -->
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_tres_aqi}}</td>
                                        <!-- <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_cuatro_aqi}}</td> -->
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_cinco_aqi}}</td>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{capture_log.prom_seis_aqi}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col mt-2"  *ngFor="let item of capture_log.prom | keyvalue">
                            <p class="semibold">Promedios {{item.key}}</p>
                            <table style="border: 1px solid black;border-collapse: collapse;">
                                <thead>
                                    <tr>
                                        <th style="border: 1px solid black;border-collapse: collapse;">10 Min</th>
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Hora</th>
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Día</th>
                                        <th style="border: 1px solid black;border-collapse: collapse;">1 Semana</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{ item.value['10M'] }}</td>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{ item.value['1H'] }}</td>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{ item.value['1D'] }}</td>
                                        <td style="border: 1px solid black;border-collapse: collapse;">{{ item.value['1S'] }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                   <!--  <div class="row">
                        <div class="px-4 text_quality_p col-9 col-sm-9">
                            <p class="semibold">{{capture_log.created_at}}</p>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-3 col-md-3 p-sm-0 p-0">
                <div class="ul_map">
                    <ul class="align-items-end align-items-sm-end d-flex flex-column">
                        <li class="align-items-center"> <i class="las la-tint fs-big"></i>
                            <small for="">Hume.</small>
                            <label class="semibold ">{{capture_log.humidity}}</label>
                        </li>
                        <li class="align-items-center"> <i class="las la-thermometer-half fs-big"></i>
                            <small for="">Temp.</small>
                            <label class="semibold">{{capture_log.temperature}}</label>
                        </li>
                        <li class="align-items-center"> <i class="las la-cloud-sun-rain fs-big"></i>
                            <small for="">Presión</small>
                            <label class="semibold">{{capture_log.pressure}}</label>
                        </li>
                        <!-- <li class="align-items-center" *ngIf="deviceSelect"> 
                            <i class="las la-chart-bar fs-normal chart_icon pointer" (click)="showAirScale = true"></i>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="w-100 mt-4">
                <div *ngIf="moreDetail">
                    <button type="button" class="btn btn-outline-light" (click)="showDetail()">Ampliar Información</button>
                </div>
            </div>
        </div>

    </div>
    <!-- <app-air-scale *ngIf="showAirScale" [showAirScale]="showAirScale" [captureLog]="capture_log"  [device]="device" (changeShowAirScale)="getShowAirScale($event)"></app-air-scale> -->

</div>
