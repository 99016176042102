<div class="info d-sm-block d-none">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center b-right mr-4 pr-4">
            <i class="las la-desktop color-primary fs-big mr-3"></i>
            <p class="f-subtitle color-primary fs-normal nowrap">Dashboard</p>
          </div>
          <p class="color-gray3 x-small d-md-block d-none">Estas son las cifras Globales mas relevantes dentro de los
            registros de la plataforma.</p>
        </div>
      </div>
    </div>
  </div>
  
  <div class="box mb-4 px-sm-4 px-3 dashboard-box">
    <div class="row dashboard-row">
      <div class="col-lg-3 b-right dashboard-col">
        <div class="d-flex align-items-sm-center align-items-start p-4">
          <i class="las la-industry color-primary fs-big mr-2"></i>
          <div class="d-flex align-items-center flex-sm-row flex-column">
            <span class="color-primary fs-normal semibold mr-3">{{countCompanies}}</span>
            <p class="color-gray3">Empresas</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 b-right dashboard-col">
        <div class="d-flex align-items-sm-center align-items-start p-4">
          <i class="las la-broadcast-tower color-primary fs-big mr-2"></i>
          <div class="d-flex align-items-center flex-sm-row flex-column">
            <span class="color-primary fs-normal semibold mr-3">{{countDevicesActive}}</span>
            <p class="color-gray3">Estaciones activas</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 b-right dashboard-col">
        <div class="d-flex align-items-sm-center align-items-start p-4">
          <i class="las la-user color-primary fs-big mr-2"></i>
          <div class="d-flex align-items-center flex-sm-row flex-column">
            <span class="color-primary fs-normal semibold mr-3">{{countUsers}}</span>
            <p class="color-gray3">Usuarios</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 dashboard-col">
        <div class="d-flex align-items-sm-center align-items-start p-4">
          <i class="las la-file color-primary fs-big mr-2"></i>
          <div class="d-flex align-items-center flex-sm-row flex-column">
            <span class="color-primary fs-normal semibold mr-3">{{countCaptureLog}}</span>
            <p class="color-gray3">Lecturas del día</p>
          </div>
        </div>
      </div>
    </div>
  </div>