<script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
<script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
<div class="row dashboard-row-graphics">
    <div class="col-lg-12 pl-lg-1">
        <form [formGroup]="form" class="inn-form" (ngSubmit)="report($event, '')" novalidate>
            <div class="box bg-white mb-4 align-items-center justify-content-center p-5 form-content">
                <div class="col-lg-2 form-group mb-1">
                    <label class="color-primary">Filtro de gráficas</label>
                </div>
                <div class="col-lg-2 form-group mb-1" *ngIf="selectCompany">
                    <label id="paginador_tabla" class="color-primary">
                        Cliente:</label>
                    <select #company_id class="form-control" formControlName="company_id"
                        (change)='changeSelectCompany(company_id.value)'>
                        <option value="">Seleccionar</option>
                        <option [value]="company.id" *ngFor="let company of companies">{{company.name}}</option>
                    </select>
                    <app-validation-error [form]="form" [field]="'company_id'"></app-validation-error>
                </div>
                <div class="col-lg-2 form-group mb-1" *ngIf="selectDevice">
                    <label id="device_id" class="color-primary">Estación: </label>
                    <div *ngIf="user.role_id == 4; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                        <select class="form-control" #device_id formControlName="device_id" (change)='changeSelectDevice(device_id.value)' >
                            <option value="">Seleccionar</option>
                            <option [value]="device.id" [name]="device.name" *ngFor="let device of devices">{{device.name}}
                            </option>
                        </select>
                    </ng-template>
                    <ng-template #elseBlock>
                        <select class="form-control" #device_id formControlName="device_id" (change)='changeSelectDevice(device_id.value)' >
                            <option value="">Seleccionar</option>
                            <option [value]="device.device_id" [name]="device.name" *ngFor="let device of devices">{{device.name}}
                            </option>
                        </select>
                    </ng-template>
                    <app-validation-error [form]="form" [field]="'device_id'"></app-validation-error>
                </div>
                <div class="col-lg-2 form-group mb-1">
                    <label id="range_date" class="color-primary">
                        Rango de fechas:</label>
                    <input type="text" placeholder="DD/MM/AAAA" class="form-control" #range_date
                        formControlName="range_date" type="text" name="datetimes"
                        [bsConfig]="{withTimepicker: true, rangeInputFormat : 'MMMM Do YYYY, h:mm a', dateInputFormat: 'MMMM Do YYYY, h:mm a'}"
                        bsDaterangepicker>
                    <app-validation-error [form]="form" [field]="'range_date'"></app-validation-error>

                </div>
                <div class="col-lg-2 form-group mb-1">
                    <button type="submit" class="btn btn-primary f-subtitle">
                        <span class="d-md-block">Filtrar</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-lg-12 pl-lg-1" *ngIf="showDetail">
        <div class="box bg-white mb-4 d-flex align-items-center justify-content-between d-flex p-5">
            <h3 class="col-lg-5 f-subtitle color-primary">{{device.name}}</h3>
            <div class="col-lg-3 form-group mb-0">
                <label class="color-primary">Equipo / Estación de Monitoreo</label>
            </div>

            <div class="col-lg-2 form-group mb-0 " *ngIf="showCountComment">
                <button class="btn btn-light" (click)="showComment()">
                    <i class="las la-comment-dots fs-normal mr-2"></i>
                    <span
                        class="position-absolute  start-100 translate-end badge badge_comment badge-primary rounded-circle">
                        {{comments.length}}
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-lg-12 pl-lg-1" >
        <div class="box bg-white mb-4 pb-4">
            <div id="chart-aqi"></div>
            <div class="row">
                <div class="col">
                    <div class="form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                        <input type="color" value="#36CC4A" disabled="true">
                        <label>&nbsp; Bueno</label>
                    </div>
                    <div class="form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                        <input type="color" value="#E1E12C" disabled="true">
                        <label>&nbsp; Aceptable</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                        <input type="color" value="#F58D00" disabled="true">
                        <label>&nbsp; Regular</label>
                    </div>
                    <div class="form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                        <input type="color" value="#E74C3C" disabled="true">
                        <label>&nbsp; Dañino</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                        <input type="color" value="#BE00F5" disabled="true">
                        <label>&nbsp; Muy dañino</label>
                    </div>
                    <div class="form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                        <input type="color" value="#784212" disabled="true">
                        <label>&nbsp; Peligrosa</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 pl-lg-1" >
        <div class="box bg-white mb-4 pb-4">
            <div id="chart-temperature"></div>
            <div class="col form-group mb-1 mt-4 text-center" *ngIf="btnHistoricReport">
            </div>
        </div>
    </div>
    <div class="col-lg-6 pl-lg-1">
        <div class="box bg-white mb-4 pb-4">
            <div id="chart-humidity"></div>
            <div class="col form-group mb-1 mt-4 text-center" *ngIf="btnHistoricReport">
            </div>
        </div>
    </div>
    <div class="col-lg-6 pl-lg-1">
        <div class="box bg-white mb-4 pb-4">
            <div id="chart-pressure"></div>
            <div class="col form-group mb-1 mt-4 text-center" *ngIf="btnHistoricReport">
            </div>
        </div>
    </div>
        <div class="col-lg-6 pl-lg-1" >
        <div class="box bg-white mb-4 pb-4">
            <div id="chart-pm"></div>
            <div class="col form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                <input type="color" value="#ff0000" disabled="true">
                <label>&nbsp; Limite maximos permitidos para material particulado</label>
            </div>
            <div class="col form-group mb-1 mt-4 text-left" *ngIf="btnHistoricReport">
                <input type="color" value="#3EBD4F" disabled="true">
                <label>&nbsp; Limite recomendados por la OMS</label>
            </div>
        </div>
    </div>
    <div class="col-lg-6 pl-lg-1" *ngFor="let ind of countArray; let i = index">
        <div class="box bg-white mb-4 pb-4">
            <div id="chart{{i}}"></div>
            <div class="col form-group mb-1 mt-4 text-center" *ngIf="btnHistoricReport">
            </div>
        </div>
    </div>
    <div class="col-lg-12 pl-lg-1" *ngIf="btnHistoricReport">
        <div class="box bg-white mb-4 d-flex align-items-center justify-content-center d-flex p-5">
            <a class="btn btn-primary" (click)="download()">
                <i class="las la-file-excel fs-normal mr-2"></i>
                Solicitar Informe
            </a>
        </div>
    </div>

</div>
<app-modal-comment [opened]="opened" [countComment]="comments.length" [comments]="comments" [values]="values" [result]="result" (refreshTemplate)="refresh($event)"></app-modal-comment>
<script>
    $(function () {
        $('input[name="datetimes"]').daterangepicker({
            timePicker: true,
            startDate: moment().startOf('hour'),
            endDate: moment().startOf('hour').add(32, 'hour'),
            locale: {
                format: 'M/DD hh:mm A'
            }
        });
    });
</script>
