import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApirestService } from '@core/services/apirest.service';
import { SingletonService } from '@core/services/singleton.service';
import * as moment from 'moment';
import 'moment/locale/es';

@Component({
  selector: 'app-modal-comment',
  templateUrl: './modal-comment.component.html',
  styleUrls: ['./modal-comment.component.scss'],
})
export class ModalCommentComponent implements OnInit {
  @Input() opened;
  @Input() comments;
  @Input() countComment;
  @Input() values;
  @Input() result;

  // -- Confirm modal variables --//
  @ViewChild('modalConfirm') modalConfirm;
  confirmMessage = '';
  commentId = 0;

  @Output() refreshTemplate = new EventEmitter<boolean>();

  form!: FormGroup;

  user : any;
  createdComment = false;
  
  constructor(
    private formBuilder: FormBuilder,
    public service: ApirestService,
    public singleton: SingletonService
  ) {
    this.user = this.singleton.getSessionUser();


    if(1 == this.user.role_id || 3 == this.user.role_id )
    {
        this.createdComment = true;
    }
    this.confirmMessage = '¿Está seguro de eliminar este comentario?';
    this.buildForm();
  }

  ngOnInit(): void {}

  private buildForm() {
    this.form = this.formBuilder.group({
      id: [0],
      comments: ['', [Validators.required]],
    });
  }

  close() {
    this.opened = false;
    this.refreshTemplate.emit(true);
  }

  save(event: Event) {
    
    event.preventDefault();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    let start = moment(this.values.range_date[0]).format("YYYY-MM-DD HH:mm");
    let end = moment(this.values.range_date[1]).format("YYYY-MM-DD HH:mm");

    let values = Object.assign({}, this.form.value);
    let body = new FormData();
    body.append('device_id', this.values['device_id']);
    body.append('company_id', this.values['company_id']);
    body.append('comments', values.comments);
    body.append('start_date', start );
    body.append('end_date', end );
    body.append('device_capture', JSON.stringify(this.result) );

    let url = 'device-analysis';

    if(0 != values.id)
    {
      url = `device-analysis/${values.id}`;
			body.append('_method', 'PATCH');
    }

    this.service.queryPost(url, body).subscribe(
      (response: any) => {
        //-- Close Loading
        if (response.success) {
          this.singleton.showAlert({
            type: 'success',
            content: response.message,
          });
          this.refreshTemplate.emit(true);
          this.buildForm();
        } else {
          this.singleton.showAlert({
            type: 'error',
            content: response.message,
          });
        }
      },
      (err: any) => {}
    );
  }

  /**
   *
   * Opens the modal to confirm the delete
   *
   */

  confirmDelete(id: number): void {
    this.commentId = id;
    this.modalConfirm.openModal();
  }

  /**
   *
   * Validates if the user accept or not the delete
   *
   */

  onCloseModalConfirm(accepted: boolean): void {
    if (accepted) {
      this.deleteComments();
    }
  }

  deleteComments() {
    this.service
      .queryDelete(`device-analysis/${this.commentId}`)
      .subscribe(
        (response: any) => {
          //-- Close Loading
          if (response.success) {
            this.singleton.showAlert({
              type: 'success',
              content: response.message,
            });
            this.refreshTemplate.emit(true);
          } else {
            this.singleton.showAlert({
              type: 'error',
              content: response.message,
            });
          }
        },
        (err: any) => {}
      );
  }

  showComment(id)
  {
    this.service.queryGet(`device-analysis/${id}`)
      .subscribe(
        (response:any) =>
        {
          let result = response;

          let comment = result.data
          
          this.form.setValue({
            id: comment.id,
            comments: comment.comments
          });
        },
        (err:any) =>
        {
          console.log(err);
          
        }
      )
    
  }
}
