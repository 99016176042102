<div class="container-air-scale h-auto position-absolute bg-white overflow-hidden">
  <div class="header-air-scale d-flex justify-content-between flex-wrap w-100 pl-5 pr-5 pt-3 pb-3" [ngStyle]="{'background-color': captureLog.color}">
    <div class="d-flex align-items-center">
      <i class="las la-long-arrow-alt-left pointer color-gray4" (click)="changeShow()" [ngStyle]="{'background-color': captureLog.color}"></i>
      <h1 class="place ml-3 title-regular font-weight-bold">{{device.name}}</h1>
    </div>
    <div class="d-flex align-items-center ml-4">
      <img [src]="captureLog.url_emoticon" alt="Face" class="face-emoticon">
      <h1 class="qualification ml-3 title-regular font-weight-bold">{{captureLog.name}}</h1>
    </div>
  </div>
  <div class="body-air-scale pl-5 pr-5 pt-2 pb-5">
    <form [formGroup]="form" class="inn-form" (ngSubmit)="save($event)" novalidate>
      <div class="filters-air-scale mt-2 pb-5 pr-4 pl-4">
        <div class="form-group w-100 d-flex align-items-center justify-content-between">
          <label for="air_score" class="color-gray4 font-weight-bold col-5 pl-0 mb-0">¿Cual es tu percepción sobre la calidad del aire?</label>
          <!-- <mat-form-field class="w-100">
            <mat-label>Seleccionar estado de ánimo</mat-label>
            <mat-select formControlName="mood_id" (selectionChange)="changeValueEmoji($event.value)">
                <mat-select-trigger *ngIf="getSelectedEmoji() as selectedLabel">
                    <mat-icon><img *ngIf="emoticon == 1" src="assets/Iconografia/Emojis/Color/{{selectedLabel.emoticon}}">
                        <img *ngIf="emoticon == 2" src="assets/Iconografia/Emojis/Line/{{selectedLabel.emoticon}}">
                        <img *ngIf="emoticon == 3" src="assets/Iconografia/Emojis/Sombra/{{selectedLabel.emoticon}}">
                    </mat-icon>{{selectedLabel.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let emoji of emojis" [value]="emoji">
                    <mat-icon>
                        <img *ngIf="emoticon == 1" src="assets/Iconografia/Emojis/Color/{{emoji.emoticon}}">
                        <img *ngIf="emoticon == 2" src="assets/Iconografia/Emojis/Line/{{emoji.emoticon}}">
                        <img *ngIf="emoticon == 3" src="assets/Iconografia/Emojis/Sombra/{{emoji.emoticon}}">
                    </mat-icon>{{emoji.name}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

          <select class="form-control f-subtitle col-7 px-0" id="air_score" formControlName="air_score">
            <option value="" selected disabled>Seleccionar</option>
            <option value="5">Bueno</option>
            <option value="4">Estable</option>
            <option value="3">Regular</option>
            <option value="2">Malo</option>
            <option value="1">Dañino</option>
            <option value="0">Peligroso</option>
            <app-validation-error [form]="form" [field]="'air_score'"></app-validation-error>
          </select>
        </div>
        <div class="form-group w-100 d-flex align-items-center justify-content-between">
          <label for="breathing_score" class="color-gray4 font-weight-bold col-5 pl-0 mb-0">¿Como respiras?</label>
          <select class="form-control f-subtitle col-7 px-0" id="breathing_score" formControlName="breathing_score">
            <option value="" selected disabled>Seleccionar</option>
            <option value="3">Bien</option>
            <option value="2">Con algo de dificultad</option>
            <option value="1">Con dificultad</option>
            <app-validation-error [form]="form" [field]="'breathing_score'"></app-validation-error>
          </select>
        </div>
        <div class="form-group w-100 d-flex align-items-center justify-content-between">
          <button type="submit" class="btn btn-primary f-subtitle">
            <span class="d-none d-md-block">Enviar</span>
          </button>
        </div>
      </div>
    </form>
    <div class="chart-air-scale pl-4 pr-4 pb-5 d-flex">
      <div class="faces-air-scale d-flex flex-column">
        <img src="../../../../assets/images/emoticon-status-ica/bueno.svg" alt="Face" class="face-scale mb-3">
        <img src="../../../../assets/images/emoticon-status-ica/estable.svg" alt="Face" class="face-scale mb-3 mt-1">
        <img src="../../../../assets/images/emoticon-status-ica/regular.svg" alt="Face" class="face-scale mb-3 mt-1">
        <img src="../../../../assets/images/emoticon-status-ica/malo.svg" alt="Face" class="face-scale mb-3 mt-1">
        <img src="../../../../assets/images/emoticon-status-ica/dañino.svg" alt="Face" class="face-scale mb-3 mt-1">
        <img src="../../../../assets/images/emoticon-status-ica/peligroso.svg" alt="Face" class="face-scale mb-3 mt-1">
      </div>
      <div class="chart d-flex flex-column w-100">
        <div class="col-lg-6 pl-lg-1">
          <div class="box bg-white mb-4">
              <div id="chart-air-perception" *ngIf="showAirPerception"></div>
          </div>
      </div>
        <p class="color-gray4 x-small mt-3 text-center">índice porcentual</p>
      </div>
    </div>
  </div>
</div>
