<div class="particle position-relative">
  <img src="../../../../assets/images/particula_2.svg " alt=" " srcset=" " class="img-particle position-absolute">
  <img src="../../../../assets/images/nube.svg" alt=" " srcset=" " class="cloud position-absolute">

  <div class="container d-flex h-100 flex-column justify-content-center container-info">
    <div class="material_title color-primary">
      <p class="text-left text-break">Material</p>
      <p class="text-left font-weight-bold text-break">PARTICULADO</p>
    </div>
    <div class="">
      <div class="material_text">
        <p class="text-left mx-auto">El material particulado (PM) es el conjunto de partículas sólidas y líquidas que son desprendidas al aire por diferentes fuentes de emisión, tales como el hollín de combustión, polvo de diferentes actividades y las partículas resultantes de procesos industriales. Estas partículas son una compleja mezcla de productos químicos que pueden interactuar entre sí formando compuestos contaminantes.</p>
      </div>
    </div>

  </div>

  <div class="">
    <img src="../../../../assets/images/particula.svg" alt="" srcset="" class="circle position-absolute">
  </div>
</div>


