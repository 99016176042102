<div class="partners p-0 m-0 position-relative d-flex overflow-hidden" id="partners">
  <img src="../../../../assets/images/particula.svg" alt="" srcset="" class="circle position-absolute">
  <img src="../../../../assets/images/forma_circulo.png" alt="" srcset="" class="position-absolute background-image">
  <div class="w-100 container-info">
    <div class="w-100  text mx-0  my-5 p-4 fs-small d-flex justify-content-center flex-nowrap">
     <!--  <h3 class="pt-2 fs-bigger">Colaboradores</h3> -->
    </div>
    <div class="container">
      <div class="justify-content-center testimonials d-xs-none">

        <!-- <carousel [isAnimated]="true" [showIndicators]="false" [itemsPerSlide]="itemsPerSlide" class="justify-content-between">
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_cedenar.png" alt="Cedenar" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Cedenar</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_celsia.png" alt="Celsia " srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Celsia</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_CHEC.png" alt="CHEC" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">CHEC</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_eebp.png" alt="Empresa de energía del Bajo Putumayo" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Empresa de energía del Bajo Putumayo</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_eep.png" alt="Empresa de energía de Pereira" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Empresa de energía de Pereira</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_electrohuila.png" alt="Electrohuila" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Electrohuila</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_emcartago.png" alt="Emcartago" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Emcartago</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_enelar.png" alt="Enelar E.S.P" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Enelar E.S.P</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_magnetron.png" alt="Magnetron" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Magnetron</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_ingelectrica.jpg" alt="Ingeléctrica S.A" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Ingeléctrica S.A</h6>
            </div>
          </slide>

        </carousel> -->
      </div>
<!--       <div class="justify-content-center testimonials d-sm-none">

        <carousel [isAnimated]="true" [showIndicators]="false" class="justify-content-between">
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_cedenar.png" alt="Cedenar" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Cedenar</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_celsia.png" alt="Celsia " srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Celsia</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_CHEC.png" alt="CHEC" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">CHEC</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_eebp.png" alt="Empresa de energía del Bajo Putumayo" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Empresa de energía del Bajo Putumayo</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_eep.png" alt="Empresa de energía de Pereira" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Empresa de energía de Pereira</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_electrohuila.png" alt="Electrohuila" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Electrohuila</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_emcartago.png" alt="Emcartago" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Emcartago</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_enelar.png" alt="Enelar E.S.P" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Enelar E.S.P</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_magnetron.png" alt="Magnetron" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Magnetron</h6>
            </div>
          </slide>
          <slide style="width: 100%">
            <div class="text-center py-5 bg-white text-black">
              <img src="assets/images/Clientes/logo_ingelectrica.jpg" alt="Ingeléctrica S.A" srcset=" " class="rounded-circle">
              <h6 class="opinion_title font-weight-bold py-4">Ingeléctrica S.A</h6>
            </div>
          </slide>

        </carousel>
      </div> -->
    </div>

  </div>

</div>
