<footer class="footer d-flex text-left fs-small">
    <div class="img">
        <img src="../../../../assets/images/simbolo_footer.svg" alt="" srcset="" class="position-absolute">
    </div>
    <div class="container">
        <div class="row  py-5 justify-content-between">
<!--            <div class="col-2 d-none d-xl-flex"></div>-->
            <div class=" fs-small py-4 px-3">
                <h6 class="pb-4">Propiedad Intelectual</h6>
                <p class="responsability">GESTAM es propietaria de la información contenida en este portal, así como de los elementos contenidos en ella, los cuales están protegidos conforme a la ley de propiedad intelectual</p>
            </div>
            <div class="fs-small d-flex flex-column py-4 px-3">
                <h6 class="pb-4">Síguenos</h6>
                <div class="col m-0 p-0">
                    <i class="lab la-facebook pr-2"></i>
                    <a href="https://www.facebook.com/laboratoriogestam" target="_blank" class="m-0">Gestam Oficial</a>
                </div>
                <div class="col m-0 p-0">
                    <i class="lab la-instagram pr-2"></i>
                    <a href="https://www.instagram.com/gestam/" target="_blank" class="m-0">@gestamoficial</a>
                </div>
                <div class="col m-0 p-0">
                    <i class="lab la-linkedin pr-2"></i>
                    <a href="https://co.linkedin.com/in/gestam-laboratorio-ambiental-939857215" target="_blank" class="m-0">Gestam Oficial</a>
                </div>
                <div class="col m-0 p-0">
                    <i class="las la-pager pr-2"></i>
                    <a href="https://gestam.com.co/" target= "_blank"class="m-0" >Página web Gestam</a>
                </div>
            </div>
            <div class="fs-small py-4 px-3 d-flex h-100 flex-column">
                <div class="h-100 pt-0 pt-sm-5">
                    <div class="col m-0 p-0">
                        <i class="las la-city pr-2"></i>
                        <label for="" class="m-0">Cra 32b # 101-04</label>
                    </div>
                    <div class="col m-0 p-0">
                        <i class="lab la-whatsapp pr-2"></i>
                        <label for="" class="m-0">300 304 24 18</label>
                    </div>
                    <div class="col m-0 p-0">
                        <label for="" class="m-0">Todos los derechos Reservados - Gestam 2022©</label>
                    </div>
                </div>

            </div>
        </div>

    </div>

</footer>
