import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ApirestService } from '@core/services/apirest.service';
import { SingletonService } from '@core/services/singleton.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Chart } from 'frappe-charts';

@Component({
  selector: 'app-air-scale',
  templateUrl: './air-scale.component.html',
  styleUrls: ['./air-scale.component.scss']
})
export class AirScaleComponent implements OnInit {

  @Input() showAirScale;
  @Input() captureLog;
  @Output() changeShowAirScale = new EventEmitter<any>();
  @Input() device;

  form!: FormGroup;

  showAirPerception = false;
  airPerception = ''

  constructor(
    public service: ApirestService,
    public singleton: SingletonService,
    private formBuilder: FormBuilder
  ) { 
    this.buildForm();
  }

  ngOnInit(): void {

    this.getPerception();
    //this.initAirPerception();
  }

  changeShow(): void {
    this.changeShowAirScale.emit(false)
  }

  private buildForm() {

    this.form = this.formBuilder.group({
      air_score: ['', [Validators.required]],
      breathing_score: ['', [Validators.required]]
    });
  }

  save(event: Event)
  { 
    event.preventDefault();
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			return;
		}

    let values = Object.assign({}, this.form.value);
		let body = new FormData();
		Object.keys(values).forEach(key => body.append(key, values[key]));
    body.append('device_id' , this.device.id)

    this.service.queryPost(`air-perception`, body).subscribe(
			(response: any) => {
				//-- Close Loading
        console.log(response)
				if (response.success) {
				this.singleton.showAlert({type: 'success', content: response.message});
        this.getPerception();
				} else {
				this.singleton.showAlert({type: 'error', content: response.message});
       
				}
			},
			(err: any) => {

        console.log(err);
			}
		);

  }


  getPerception(){
    let url = 'get-air-perception?device_id=' + this.device.device_id;

    this.service.queryGet(url).subscribe(
      (response: any) => {
        this.device.air_perception = response;
        let label = [];
        let value = []
        this.device.air_perception.forEach(element => {
            label.push(element.date);
            value.push(element.air_score);
        });

        console.log(this.device.air_perception);

        this.showAirPerception = true;

        //------------- Chart Months -----------//
        const dataAirPerception = {
          labels: label,
            datasets: [
                {
                    name: "aire", type: "bar",
                    values: value
                }
            ]
        }

        setTimeout(() => {
            this.airPerception = new Chart("#chart-air-perception", {  // or a DOM element,
                                                         // new Chart() in case of ES6 module with above usage
                 title: "",
                 data: dataAirPerception,
                 type: 'bar', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
                 height: 300,
                 colors: ['#C7B5FB']
             });
         }, 100);
        
      },
      err => {
      }
    );
  }

  initAirPerception()
    {   let label = [];
        let value = []
        this.device.air_perception.forEach(element => {
            label.push(element.date);
            value.push(element.air_score);
        });

        console.log(this.device.air_perception);

        this.showAirPerception = true;

        //------------- Chart Months -----------//
        const dataAirPerception = {
          labels: label,
            datasets: [
                {
                    name: "aire", type: "bar",
                    values: value
                }
            ]
        }

        setTimeout(() => {
            this.airPerception = new Chart("#chart-air-perception", {  // or a DOM element,
                                                         // new Chart() in case of ES6 module with above usage
                 title: "",
                 data: dataAirPerception,
                 type: 'bar', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
                 height: 300,
                 colors: ['#C7B5FB']
             });
         }, 100);
    }

}


