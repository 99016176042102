import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApirestService } from '@core/services/apirest.service';
import { SingletonService } from '@core/services/singleton.service';
import { environment } from '@environments/environment';
import { HttpClient } from "@angular/common/http";
/* import * as moment from 'moment';
import 'moment/locale/es'; */
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  public markers = [];
  lat = 0;
  long = 0;
  zoom:number = 12;
  visibilidad=false;
  user : any;
  roleId = 0;
  contador = 0;
  moreDetail = false;
  deviceId = 0;
  deviceIdAnt = 0;
  path = 'admin';

  constructor(
    public service: ApirestService,
    public singleton : SingletonService,
    public router: Router,
    private http: HttpClient,
  ) {
    this.getPosition();
    this.getLocation();
    this.user = this.singleton.getSessionUser();
    this.roleId = this.user.role_id;

    this.singleton.updateLoading(true);

    if(2 == this.roleId)
    {
      this.path = 'tecnico';
    }
    else if(3 == this.roleId)
    {
      this.path = 'analista';
    }
    else if(4 == this.roleId)
    {
      this.path = 'cliente';
    }
   }

   showAirScale =  false;
   deviceSelect = false;

  

  capture_log = {
    date: '',
    name_device:'',
    elevation: 0,
    humidity: 0,
    ica: 0  ,
    pressure: 0,
    temperature: 0 ,
    description : '',
    color : '',
    rangos: '',
    created_at :'',
    url_emoticon : '',
    name : '',
    prom_uno_pm2_5: 0,
    prom_dos_pm2_5: 0,
    prom_tres_pm2_5: 0,
    prom_cuatro_pm2_5: 0,
    prom_cinco_pm2_5: 0,
    prom_seis_pm2_5: 0,
    prom_uno_aqi: 0,
    prom_dos_aqi: 0,
    prom_tres_aqi: 0,
    prom_cuatro_aqi: 0,
    prom_cinco_aqi: 0,
    prom_seis_aqi: 0,
    prom:{}
  }

  initMap() {
    let usuario = JSON.parse(localStorage.getItem('user_data')); 	
    let datos = {
      consulta:false,
      company: usuario.company.id,
      rol: usuario.role_id,
    };
    console.log(datos)
    this.singleton.updateLoading(true);
    this.service.queryPost(`get-data-historic-by-device`, datos)
    
    .subscribe(
      (response:any) =>
      {
        this.markers = response.result;
        this.capture_log = response.capture_log;
        this.singleton.updateLoading(false);
      },
      (err:any) =>
      {
        console.error(err);

      }
    )
  }
  ngOnInit(): void {
    setInterval (async () => {
      let usuario = JSON.parse(localStorage.getItem('user_data')); 	
      let datos = {
        consulta:false,
        company: usuario.company.id,
        rol: usuario.role_id,
      };
      this.singleton.updateLoading(true);
      await this.service.queryPost(`get-data-historic-by-device`, datos)
        .subscribe(
            (response:any) =>
      {
        this.markers = response.result;
        
        this.capture_log = response.capture_log;
        this.singleton.updateLoading(false);
      },
      (err:any) =>
      {
        console.error(err);

      }
      
    )
   }, 1000000);
  }

  markerClicked(deviceId: number)
  {
    let markerSelected =  this.markers.find(x=>x.device_id == deviceId);
    let datos = {
      codDispo:deviceId
    };
    this.singleton.updateLoading(true);
    this.http.post(`${environment.apiUrl}get-avg-pm`, datos).subscribe(
			(response: any) => {
				if (this.capture_log.name_device !== markerSelected.capture_log.name_device) {
          this.capture_log = markerSelected.capture_log;
          this.capture_log.created_at = moment(markerSelected.capture_log.created_at).add('hours',5).format("YYYY-MM-DD HH:mm");
        } else {
          this.capture_log = markerSelected.capture_log;
          this.capture_log.created_at = moment(markerSelected.capture_log.created_at).format("YYYY-MM-DD HH:mm");
        }
        this.capture_log.prom_uno_pm2_5 = (response[0]['PM2_5']['10M']).toFixed(2);
        //this.capture_log.prom_dos_pm2_5 = (response[0]['PM2_5']['30M']).toFixed(2);
        this.capture_log.prom_tres_pm2_5 = (response[0]['PM2_5']['1H']).toFixed(2);
        //this.capture_log.prom_cuatro_pm2_5 = (response[0]['PM2_5']['6H']).toFixed(2);
        this.capture_log.prom_cinco_pm2_5 = (response[0]['PM2_5']['1D']).toFixed(2);
        this.capture_log.prom_seis_pm2_5 = (response[0]['PM2_5']['1S']).toFixed(2);

        this.capture_log.prom_uno_aqi = response[0]['AQI']['10M'];
        //this.capture_log.prom_dos_aqi = response[0]['AQI']['30M'];
        this.capture_log.prom_tres_aqi = response[0]['AQI']['1H'];
        //this.capture_log.prom_cuatro_aqi = response[0]['AQI']['6H'];
        this.capture_log.prom_cinco_aqi = response[0]['AQI']['1D'];
        this.capture_log.prom_seis_aqi = response[0]['AQI']['1S'];
        let valores = {};
        Object.entries(response[0]).forEach(([key, value]) => {
          if(key!== 'AQI' && key!== 'PM2_5') {
            valores[key] = value;
          }
        }); 
        this.capture_log.prom = valores;
        this.moreDetail = markerSelected.more_detail;
        this.deviceId = markerSelected.device_id;
        this.deviceSelect = true;
				this.singleton.updateLoading(false);
        this.visibilidad = true;
			},
			(err: any) => {
				this.singleton.updateLoading(false);
			}
		);
    
  }

  showDetail()
  {
    this.router.navigate([`${this.path}/equipos/detalle/${this.deviceId}`]);
  }

  getPosition(): Promise<any> 
  {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }

  getLocation() 
  {
    navigator.geolocation.getCurrentPosition(
      resp => 
      {
        this.lat = resp.coords.latitude;
        this.long = resp.coords.longitude;

        this.initMap();
      },
      err => 
      {
        
        this.lat = 5.067427;
        this.long = -75.51935;

        this.initMap();
        
      }
    );

    
  }

  getShowAirScale(value): void {
    this.showAirScale = value;
  }

}
/**
 * It returns the number of documents in a collection that have a date greater than or equal to the
 * current date minus 5 hours.
 * </code>
 * 
 * @param Request request 
 * 
 * @return {
 *         "count": [
 *             0
 *         ]
 *     }
 */
