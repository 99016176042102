<div class="header-top-drawer d-flex d-lg-none justify-content-between align-items-center">
  <div class="d-flex justify-content-between align-items-center">
    <div class="color-secondary d-flex pointer">
      <i class="las la-bars pointer" (click)="toggleMenu('menu', '')"></i>
    </div>
  </div>

  <nav class="h-100">
    <div class="nav-menu h-100">
      <a class="pointer border-0 text-decoration-none font-weight-normal h-100" routerLinkActive="selected" href="/">
        <img class="navbar-brand navbar-img h-100" src="assets/images/admin/logo-menu.svg">
      </a>
    </div>
  </nav>

  <a class="pointer border-0 text-decoration-none font-weight-normal" href="/auth/login" target="_self">
  <div class="options">

      <i class='las la-user'></i>

  </div>
  </a>

  <div class="container-drawer container-drawer--menu position-fixed" id="drawer-menu">
    <div class="drawer drawer-menu vh-100" (click)="stopPropagation($event)">
      <div class="d-flex justify-content-end align-items-center">
        <i class="las la-long-arrow-alt-left pointer" (click)="toggleMenu('menu', '')"></i>
      </div>

      <nav class="mt-5">
        <div class="text-center mb-5">
          <a href="/" class="pointer border-0 text-decoration-none font-weight-normal">
            <img src="assets/images/admin/logo-menu.svg" class="w-75" alt="Logo">
          </a>
        </div>
        <div class="spacer5"></div>
        <div class="d-flex justify-content-center">
          <ul class="items-menu mt-3 d-flex justify-content-center flex-column">
            <li class="d-flex align-items-center mt-3 pointer" (click)="toggleMenu('menu', 'map')">
              <i class="las la-map"></i><span class="item-menu-text">MAPA</span>
            </li>
            <li class="d-flex align-items-center mt-3 pointer" (click)="toggleMenu('menu', 'know_us')">
              <i class="las la-user-friends"></i><span class="item-menu-text">CONOCENOS</span>
            </li>
            <li class="d-flex align-items-center mt-3 pointer" (click)="toggleMenu('menu', 'partners')">
              <i class="lar la-handshake"></i><span class="item-menu-text">COLABORADORES</span>
            </li>
            <li class="d-flex align-items-center mt-3 pointer" (click)="toggleMenu('menu', 'contact')">
              <i class="lar la-address-card"></i><span class="item-menu-text">CONTACTO</span>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="drawer-overlay w-100 vh-100" (click)="toggleMenu('menu', '')"></div>
  </div>
</div>
