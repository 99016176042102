import { Component, OnInit, Input } from '@angular/core';
import { ApirestService } from '@core/services/apirest.service';
import { SingletonService } from '@core/services/singleton.service';
import { HttpClient } from "@angular/common/http";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {environment} from '@environments/environment';
import * as moment from 'moment';
import 'moment/locale/es';
import Swal from 'sweetalert2';
import * as Highcharts from "highcharts/highstock";
import HighchartsMore from 'highcharts/highcharts-more';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
HighchartsMore(Highcharts);
HC_exporting(Highcharts);
HC_exportData(Highcharts);
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {

  opened = false;
  chartIca;
  chartTemperature;
  chartHumidity;
  chartPressure;
  chartPm;
  chartElevation;
  countArray: number[];
  @Input() companies;
  @Input() selectCompany;
  @Input() selectDevice;
  @Input()
  set companyId(companyId: string)
  {
    this.form.setValue({
        company_id : companyId ,
        range_date : '',
        device_id : '',
    });
    this.getDevice(companyId);
    this.changeSelectCompany(companyId);
  }
  @Input()
  set _devices(devices)
  {
      //this.devices = devices;
      this.devices = devices;
  }
  @Input()
  set deviceId(deviceId: string)
  {
    this.form.setValue({
        company_id : '',
        range_date : '',
        device_id : deviceId ,
    });

    this.changeSelectDevice(deviceId);
  }

  showDetail = false;
  user = this.singleton.getSessionUser();
  showChartIca = false;
  showChartTemperature = false;
  showChartHumidity = false;
  showChartPressure = false;
  showChartElevation = false;
  idEquipo:'';
  reportIca = [];
  reportPm = [];
  reportTemperature = [];
  reportHumidity = [];
  reportPressure  = [];
  reportElevation  = [];
  countChart = 0;
  locale = 'es';
  locales = listLocales();

  devices = [];

  form!: FormGroup;


  device:any;

  btnHistoricReport = false;
  values : any ;
  result : any;
  dates: any;
  datesFinal : any;

  comments = []
  showCountComment = false;

  totalAverages : any = [];

  //-- Variables pagination
  currentPage = 1;
  pages = [];
  totalPages = 1;
  links : any;
  perPage = 10;

  constructor(
    public service: ApirestService,
    public singleton: SingletonService,
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.applyLocale();
  }

  private buildForm() {
    let validatorsCompanies = [];
    let validatorsDevices = [];

    if (this.selectCompany == true) {
      validatorsCompanies.push(Validators.required);
    }

    if (this.selectDevice == true) {
      validatorsDevices.push(Validators.required);
    }

    this.form = this.formBuilder.group({
      company_id: ['', validatorsCompanies],
      range_date: ['', [Validators.required]],
      device_id: ['', validatorsDevices],
    });
  }

  applyLocale() {
    this.localeService.use(this.locale);
  }

  changeSelectCompany(companyId)
  {

    let values = Object.assign({}, this.form.value);

    this.devices = [];

    this.form.setValue({
        company_id : companyId ,
        range_date : values.range_date,
        device_id : '',
        /* frequency : values.frequency */
    });

    this.getDevice(companyId);

  }

  getDevice(companyId)
  {
    this.http.post(`${environment.apiUrl}companies-devices/consultForCompany/${companyId}`,{})
    .subscribe((response: any) => {
      response.forEach((element) => {
        this.devices.push({device_id: element[0], name: element[1]})
      });
    },(err: any) => {console.log(err)});
  }

  changeSelectDevice(deviceId){
    this.device = [];
    this.showDetail = true;
  }
  /**
   * La función getPage comprueba si la página dada es diferente de la página actual e informa un
   * evento si no son iguales.
   * @param page - El parámetro "página" es la página a la que el usuario desea navegar.
   */
  getPage(page) {
    if (page != this.currentPage) this.report(event, page);
  }

  /**
   * La función "informe" recupera datos de una API según la entrada del usuario, procesa los datos e
   * inicializa varios gráficos para mostrar los datos.
   * @param event - El parámetro `evento` es un objeto que representa el evento que activó la función
   * de informe. Normalmente se utiliza para evitar el comportamiento predeterminado del evento, como
   * el envío de formularios.
   * @param page - El parámetro "página" es la página o componente actual donde se llama a la función
   * "informe". Se utiliza para navegar a diferentes páginas o componentes dentro de la aplicación.
   * @returns La función no tiene una declaración de devolución.
   */
  report(event, page){
    this.singleton.updateLoading(true);
    this.reportIca = [];
    this.reportTemperature = [];
    this.reportHumidity = [];
    this.reportPressure = [];
    this.reportElevation = [];
    this.reportPm = [];
    event.preventDefault();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.values = Object.assign({}, this.form.value);
    let start = moment(this.values.range_date[0]).format("YYYY-MM-DD HH:mm");
    let end = moment(this.values.range_date[1]).format("YYYY-MM-DD HH:mm");
    let usuario = JSON.parse(localStorage.getItem('user_data'));
    let id;
    if (usuario.role_id === 2) {
      id = this.devices.find(device => this.values.device_id === device.device_id);
      id = id.id;
    } else {
      id = this.values.device_id;
    }
    this.idEquipo = this.values.device_id;
    let url = `${environment.apiUrl}get-data-historic/${start}/${end}/${id}`;
    console.time('temporizar')
    this.http.get(url)
      .subscribe(async (response: any) => {
        if(response.success){
          this.result = response.data;
          this.totalAverages = response.total_averages;
          this.countArray = Array(Object.entries(response.names).length).fill(0).map((x, i) => i);
          this.result.forEach(element => {
            let fecha = element.date;
            fecha = new Date(fecha);
            fecha = fecha.getTime();
            if (element.ica>0 && element.ica<800) this.reportIca.push([fecha, element.ica]);
            this.reportTemperature.push([fecha, element.temperatura]);
            this.reportHumidity.push([fecha, element.humidity]);
            if (element.ica>0 && element.ica<500) this.reportPm.push([fecha, element.pm2])
            this.reportPressure.push([fecha, element.pressure]);
          });
          await this.sleep(3000);
          this.initCharts(response.data, response.names)
          this.initChartIca();
          this.initChartTemperature();
          this.initChartHumidity();
          this.initChartPressure();
          this.initChartPm2();
          this.initChartElevation();
          this.comments = [];
          console.timeEnd('temporizar')
          this.singleton.updateLoading(false);
        } else {
          this.singleton.updateLoading(false);
          Swal.fire({
            title: 'Aviso!',
            icon: 'warning',
            text: 'No hay información en este rango de fechas',
            confirmButtonColor: '#5691EF',
            cancelButtonColor: '#BBBBBB',
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          })
        }
        
      },(err: any) => {this.singleton.updateLoading(false);});
  }
  /**
   * La función `download()` envía una solicitud POST al servidor con algunos datos y muestra un
   * mensaje de éxito si la solicitud tiene éxito.
   */
  download(){
    let values = Object.assign({}, this.form.value);
    let usuario = JSON.parse(localStorage.getItem('user_data'));
    let start = moment(values.range_date[0]).format("YYYY-MM-DD HH:mm");
    let end = moment(values.range_date[1]).format("YYYY-MM-DD HH:mm");
    let data = {
      companyID:values.company_id,
      startDate:start,
      endDate:end,
      correo: usuario.email,
      codDevice: this.idEquipo
    };
    this.http.post(`${environment.apiUrl}reporte/empresa`,data)
    .subscribe ((response:any) => {
      Swal.fire({
        title: 'Aviso!',
        icon: 'info',
        text: 'Correo Enviado',
      })
    },(err:any) => {console.log(err)})
  }
  /**
  * La función `initChartIca()` inicializa un gráfico de acciones de Highcharts para mostrar datos
  * de AQI (Índice de calidad del aire).
  */
  initChartIca()
    {
        /* Highcharts: typeof Highcharts = Highcharts; */
        let label = ''
        let ica = this.reportIca.sort();
        //------------- Chart Months -----------//
        this.chartIca = Highcharts.stockChart('chart-aqi', {
            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -100,
                y: 0,
                floating: true,
                borderWidth: 0
            },
            rangeSelector: {
                buttons: [ {
                    type: 'month',
                    count: 3,
                    text: '3m'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6m'
                }, {
                    type: 'ytd',
                    text: 'YTD'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y'
                }, {
                    type: 'all',
                    text: 'All'
                }],
				inputEnabled: false

            },
            time: {
                useUTC: true
            },
            title: {
                text: 'AQI'
            },
            subtitle: {
                text: ' '
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 700,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            yAxis: {
                /* plotLines: [{
                    value: 50.99,
                    color: '#36CC4A',
                    dashStyle: 'shortdash',
                    width: 2,
                }, {
                    value: 100.99,
                    color: '#E1E12C',
                    dashStyle: 'shortdash',
                    width: 2,
                }, {
                    value: 150.99,
                    color: '#F58D00',
                    dashStyle: 'shortdash',
                    width: 2,
                }, {
                    value: 200.99,
                    color: '#E74C3C',
                    dashStyle: 'shortdash',
                    width: 2,
                }, {
                    value: 300.99,
                    color: '#BE00F5',
                    dashStyle: 'shortdash',
                    width: 2,
                }, {
                    value: 500.99,
                    color: '#784212',
                    dashStyle: 'shortdash',
                    width: 2,
                }, ] */
                plotBands: [{
                    color: '#CFFFE2', /* verde */
                    from: 0,
                    to: 50.99
                },{
                    color: '#FDF5D6', /* amarrillo */
                    from: 51,
                    to: 100.99
                }, {
                    color: '#FFC59F', /* naranja */
                    from: 101,
                    to: 150.99
                }, {
                    color: '#FFABA2', /* rojo */
                    from: 151,
                    to: 200.99
                }, {
                    color: '#E4A3FF', /* Púrpura */
                    from: 201,
                    to: 300.99
                }, {
                    color: '#9C8169', /* marron */
                    from: 301,
                    to: 500.99
                }],
                labels: {
                    style: {
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }
                }

            },
            series: [{
                name: 'AQI',
                data: ica,
                tooltip: {
                    valueSuffix: ''
                }
            }],
            tooltip: {
                useHTML: true,
                shared: true,
                borderRadius: 9,

            },
            credits: {
                enabled: false
            },

        }as any);
        this.showChartIca = true;
        this.btnHistoricReport = true;
  }
  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  initCharts(data, name)
  {
      /* Highcharts: typeof Highcharts = Highcharts; */
      let label = ''
      let dataHighchart = [];
      let dataValue;
      //------------- Chart Months -----------//
      for (let index = 0; index < Object.entries(name).length; index++) {
        dataValue = Object.keys(name);  
        data.forEach(element => {
            let fecha = element.date;
            fecha = new Date(fecha);
            fecha = fecha.getTime();
            dataHighchart.push([fecha, element[dataValue[index]]]);
        });
        label = `chart${index}`;
        Highcharts.stockChart(label, {
              legend: {
                  enabled: true,
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'top',
                  x: -100,
                  y: 0,
                  floating: true,
                  borderWidth: 0
              },
              rangeSelector: {
                    buttons: [ {
                        type: 'month',
                        count: 3,
                        text: '3m'
                    }, {
                        type: 'month',
                        count: 6,
                        text: '6m'
                    }, {
                        type: 'ytd',
                        text: 'YTD'
                    }, {
                        type: 'year',
                        count: 1,
                        text: '1y'
                    }, {
                        type: 'all',
                        text: 'All'
                    }],
                inputEnabled: false
              },
              time: {
                  useUTC: true
              },
              title: {
                  text: dataValue[index]
              },
              subtitle: {
                  text: ' '
              },
              responsive: {
                  rules: [{
                      condition: {
                          maxWidth: 700,
                      },
                      chartOptions: {
                          legend: {
                              layout: 'horizontal',
                              align: 'center',
                              verticalAlign: 'bottom'
                          }
                      }
                  }]
              },
              yAxis: {
                  
              },
              series: [{
                  name:dataValue[index],
                  data: dataHighchart,
                  tooltip: {
                      valueSuffix: ''
                  }
              }],
              tooltip: {
                  useHTML: true,
                  shared: true,
                  borderRadius: 9,

              },
              credits: {
                  enabled: false
              },

          }as any);
          dataHighchart = [];
      }
}

  /**
  * La función `initChartTemperature()` inicializa un gráfico de acciones de Highcharts para mostrar
  * datos de temperatura.
  */
  initChartTemperature()
    {

        let temp = this.reportTemperature.sort();
        //------------- Chart Months -----------//
        this.chartTemperature = Highcharts.stockChart('chart-temperature', {

            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -100,
                y: 0,
                floating: true,
                borderWidth: 0
            },

            rangeSelector: {
                buttons: [ {
                    type: 'month',
                    count: 3,
                    text: '3m'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6m'
                }, {
                    type: 'ytd',
                    text: 'YTD'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y'
                }, {
                    type: 'all',
                    text: 'All'
                }],
				inputEnabled: false

            },

            time: {
                useUTC: true
            },
            title: {
                text: 'Temperatura'
            },
            subtitle: {
                text: ' '
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 700,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            series: [{
                name: 'Temperatura',
                data: temp,
                tooltip: {
                    valueSuffix: '°C'
                }
            }],
            yAxis: [{ // Primary yAxis
                title: {
                    text: 'Temperatura °C',
                },
                labels: {
                    style: {
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }
                }
            }],
            tooltip: {
                useHTML: true,
                shared: true,
                borderRadius: 9,

            },
            credits: {
                enabled: false
            },
        }as any);
        this.showChartTemperature = true;
  }
  /**
  * La función `initChartHumidity()` inicializa un gráfico de acciones de Highcharts para mostrar
  * datos de humedad.
  */
  initChartHumidity()
    {
        let humidity = this.reportHumidity.sort();
        //------------- Chart Months -----------//
        this.chartHumidity = Highcharts.stockChart('chart-humidity', {

            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -100,
                y: 0,
                floating: true,
                borderWidth: 0
            },


            rangeSelector: {
                buttons: [ {
                    type: 'month',
                    count: 3,
                    text: '3m'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6m'
                }, {
                    type: 'ytd',
                    text: 'YTD'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y'
                }, {
                    type: 'all',
                    text: 'All'
                }],
				inputEnabled: false

            },
            time: {
                useUTC: true
            },
            title: {
                text: 'Humedad'
            },
            subtitle: {
                text: ' '
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 700,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            series: [{
                name: 'Humedad',
                data: humidity,
                tooltip: {
                    valueSuffix: '%'
                }
            }],
            yAxis: [{
                title: {
                    text: 'Humedad %',
                },
                labels: {
                    style: {
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }
                }
            }],
            tooltip: {
                useHTML: true,
                shared: true,
                borderRadius: 9,

            },
            credits: {
                enabled: false
            },
        }as any);
        this.showChartHumidity = true;
  }
  /**
  * La función `initChartPressure()` inicializa un gráfico de acciones de Highcharts para mostrar
  * datos de presión.
  */
  initChartPressure()
    {
        let presion = this.reportPressure.sort();
        //------------- Chart Months -----------//
        this.chartPressure = Highcharts.stockChart('chart-pressure', {

            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -100,
                y: 0,
                floating: true,
                borderWidth: 0
            },

            rangeSelector: {
                buttons: [ {
                    type: 'month',
                    count: 3,
                    text: '3m'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6m'
                }, {
                    type: 'ytd',
                    text: 'YTD'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y'
                }, {
                    type: 'all',
                    text: 'All'
                }],
				inputEnabled: false

            },
            time: {
                useUTC: true
            },
            title: {
                text: 'Presión'
            },
            subtitle: {
                text: ' '
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 700,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            series: [{
                name: 'Presión',
                data: presion,
                tooltip: {
                    valueSuffix: 'Hpa'
                }
            }],
            yAxis: [{ // Primary yAxis
                title: {
                    text: 'Presión Hpa',
                },
                labels: {
                    style: {
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }
                }
            }],
            tooltip: {
                useHTML: true,
                shared: true,
                borderRadius: 9,

            },
            credits: {
                enabled: false
            },
        }as any);
        this.showChartPressure = true;

        //------------- Chart Months -----------//
        /* const dataPressure = {
            labels: label,
            datasets: [
                {
                    name: "Presión Hpa", type: "bar",
                    values: pressure
                }
            ]
        } */
        /* setTimeout(() => {
            this.chartPressure = new Chart("#chart-pressure", {  // or a DOM element,
                                                        // new Chart() in case of ES6 module with above usage
                title: "Reporte Presión",
                data: dataPressure,
                type: 'line', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
                height: 300,
                colors: ['#C7B5FB']
            });
        }, 100); */
  }
  /**
  * La función `initChartElevation()` inicializa un gráfico para mostrar datos de elevación usando
  * la biblioteca Chart.js en TypeScript.
  */
  initChartElevation(){
    let label = []
    let elevation = []
    this.reportElevation.forEach(element => {
      label.push(element.date);
      elevation.push([element.date,element.report]);
    });
    this.showChartElevation = true;
    //------------- Chart Months -----------//
    const dataElevation = {
      labels: label,
      datasets: [
        {
          name: "Altura m", type: "bar",
          values: elevation
        }
      ]
    }
  }
  /**
  * La función `initChartPm2()` inicializa un gráfico de acciones de Highcharts para mostrar datos
  * de PM2.5.
  */
  initChartPm2(){
    let presion = this.reportPm.sort();
    //------------- Chart Months -----------//
    this.chartPm = Highcharts.stockChart('chart-pm', {
      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -100,
        y: 0,
        floating: true,
        borderWidth: 0
      },
      rangeSelector: {
        buttons: [ {
          type: 'month',
          count: 3,
          text: '3m'
        }, {
          type: 'month',
          count: 6,
          text: '6m'
        }, {
          type: 'ytd',
          text: 'YTD'
        }, {
          type: 'year',
          count: 1,
          text: '1y'
        }, {
          type: 'all',
          text: 'All'
        }],
				inputEnabled: false
    },
    time: {
      useUTC: true
    },
    title: {
      text: 'PM2.5'
    },
    subtitle: {
      text: ' '
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 700,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    series: [{
      name: 'PM2.5',
      data: presion,
      tooltip: {
        valueSuffix: 'μg/m³'
      }
    }],
    yAxis: [{
      title: {
        text: 'PM2.5 μg/m³',
      },
      plotLines: [{
        value: 15,
        color: 'green',
        dashStyle: 'shortdash',
        width: 2,
      }, {
        value: 37,
        color: 'red',
        dashStyle: 'shortdash',
          width: 2,
        }],
        labels: {
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
          }
        }
      }],
      tooltip: {
        useHTML: true,
        shared: true,
        borderRadius: 9,
      },
      credits: {
        enabled: false
      },
    }as any);
    this.showChartPressure = true;
  }
  showComment(){
    this.opened = true;
  }
  /**
   * La función exporta un gráfico específico basado en el número dado.
   * @param number - El parámetro "número" es la clave que determina qué gráfico exportar. Se utiliza
   * para seleccionar la opción de exportación correspondiente del objeto exportOption.
   * @returns La opción de exportación correspondiente al número dado.
   */
  export(number){
    const exportOption = {
      1: this.chartIca.export(),
      2:this.chartTemperature.export(),
      3:this.chartHumidity.export(),
      4:this.chartPressure.export(),
    };
    return exportOption[number];
  }

  /**
   * La función `refresh` actualiza el objeto `values` con los valores del formulario, formatea las
   * fechas de inicio y finalización y cierra el selector de rango de fechas.
   * @param event - El parámetro `evento` es un objeto que representa el evento que activó la función
   * de actualización. Podría ser un objeto de evento de una acción del usuario, como hacer clic en un
   * botón o enviar un formulario.
   */
  refresh(event)
  {
    this.values = Object.assign({}, this.form.value);
    let start = moment(this.values.range_date[0]).format("YYYY-MM-DD HH:mm");
    let end = moment(this.values.range_date[1]).format("YYYY-MM-DD HH:mm");
    this.opened = false;
  }
}
