import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {SingletonService} from './singleton.service';

import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class ApirestService {

	apiUrl = '';
	
	constructor(public http: HttpClient, private router: Router, public singleton: SingletonService) {
		this.http = http;
		this.apiUrl = environment.apiUrl;
		/* this.apiUrl = 'http://127.0.0.1:8000/api/v1/'; */

	}

	queryPostRegular(route: string, body: any) {
		return this.http.post(this.apiUrl.concat(route), body)
		.pipe(
			catchError((error: any) => {
			this.handleError(error);
			console.log(error);
			throw('ups algo salió mal');
			})
		);
	}

	queryGet(route: string) {
		return this.http.get(this.apiUrl.concat(route))
		.pipe(
			catchError((error: any) => {
				console.log(error);
			this.handleError(error);
			throw('ups algo salió mal');
			})
		);
	}

	queryPost(route: string, body: any) {
		return this.http.post(this.apiUrl.concat(route), body)
		.pipe(
			catchError((error: any) => {
			this.handleError(error);
			console.log(error);
			throw('ups algo salió mal');
			})
		);
	}

	queryPut(route: string, body: any) {
		return this.http.put(this.apiUrl.concat(route), body)
		.pipe(
			catchError((error: any) => {
			this.handleError(error);
			console.log(error);
			throw('ups algo salió mal');
			})
		);
	}

	queryDelete(route: string) {
		return this.http.delete(this.apiUrl.concat(route))
		.pipe(
			catchError((error: any) => {
			this.handleError(error);
			console.log(error);
			throw('ups algo salió mal');
			})
		);
	}

	queryExternalApi(route: string) {
		return this.http.get(route)
		.pipe(
			catchError((error: any) => {
			this.handleError(error);
			console.log(error);
			throw('ups algo salió mal');
			})
		);
	}

	private handleError(error: any) 
    {
        if(environment.production)
        {
            Sentry.captureException(error);
        }

        let message = '';
        let logout = false;
        let navigateTo = '';

        if(error.status == 404)
        {
            message = 'The server could not process the request!';
            logout = true;
        }
        else if(error.status === 500)
        {
            this.router.navigate(['/error-500']);
        }
        else if(error.status === 422)
        {
            let result = error;
            if(result.error)
            {
                let validations = result.error;
                for (let key of Object.keys(validations)) 
                {
                    let errors = validations[key];
                    if(Array.isArray(errors) == true)
                    {
                        errors.forEach(function(error) {
                            message += error+'<br>';
                        });
                    }
                }
            }
            else
            {
                message = result.message;
            }
        }
        else if(error.status == 401 || error.status == 403)
        {
            message = 'You are not authorized to do this!';
            navigateTo = '/';
            localStorage.clear();
            //this.router.navigate(['/auth/login']);
        }
        else if(error.status == 503)
        {
            message = 'Something is wrong, it seems that it has no connection!';
            logout = true;
        }

        if(message)
        {
            this.singleton.showAlert({type: 'error', content: message});
        }
        if(logout)
        {
            this.logout();
        }
        else if(navigateTo)
        {
            this.router.navigate([navigateTo]);
        }
    }

	/**
	 *
	 * Finish the session
	 *
	 */

	logout(): void {
		localStorage.clear();
		this.router.navigate(['/']);
	}

	/**
	 *
	 * Set a language to the app
	 *
	 */

	setLanguage(language: string): void {
		const url = 'set-locale/' + language;

		this.queryGet(url).subscribe(
			(response: any) => {
				
			},
			err => { }
		);
	}

	/**
	 *
	 * List the permissions of the user
	 *
	 */

	getPermissions(): void {
		const url = 'permissions-user';
		/* this.queryGet(url).subscribe(
			(response: any) => {
				if (response.success) {
					this.singleton.permissions = response.data;
				}
			},
			err => { }
		); */
		/* this.singleton.permissions = "1"; */
	}
}