<div class="sidebar sidebar--large sidebar--right" [class.opened]="opened">
    <div class="px-4 py-3">
        <div class="row py-4">
            <div class="col text-left">
                <h6 class="color-primary f-subtitle">Comentarios</h6>
            </div>
            <div class="col"> <span class="d-flex justify-content-center badge badge-pill badge-primary rounded-circle">{{countComment}}</span>
            </div>
            <div class="col-2 p-0 m-0">
                <button class="btn btn-light btn-icon rounded translate-end" (click)="close()">
                    <i class="las la-times"></i>
                </button>
            </div>

        </div>

        <form [formGroup]="form" class="inn-form" (ngSubmit)="save($event)" novalidate *ngIf="createdComment">
            <div class="form-outline">
                <small class="color-primary f-subtitle">Ingresa un comentario</small>
                <textarea class="form-control bg-form_input mt-2" id="textAreaExample1" rows="4" placeholder="Máximo 300 caracteres" minlength="5" maxlength="300" formControlName="comments"></textarea>

            </div>
            <div class="col">
                <app-validation-error [form]="form" [field]="'comments'"></app-validation-error>
            </div>
            <div class="d-flex justify-content-around align-items-center pt-4 input-group">

                <div class="">

                    <button type="submit" class="btn-send btn btn-light position-relative d-flex align-items-center position-relative  border-0">
                        Enviar
                        <i class="lab la-telegram-plane fs-normal ml-2 "></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div>
        <div *ngFor="let comment of comments;">
            <div class="card rounded-0">
                <div class="card-body">
                    <div class="row d-flex m-0 p-0 justify-content-between ">
                        <h5 class="card-title card_title-bitacora">{{comment.user.name}} </h5><label class="text-muted">{{comment.created_at | date: 'shortDate' }}</label>
                    </div>
                    <p class="card-text card_text-bitacora">{{comment.comments}}</p>
                    <div class="d-flex justify-content-start pt-4 pointer text-secondary bitacora-icons">

                        <i class="las la-pen fs-normal ml-2" (click)="showComment(comment.id)" *ngIf="createdComment"></i>

                        <i class="las la-trash fs-normal ml-2 " (click)="confirmDelete(comment.id)" *ngIf="createdComment"></i>

                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<app-modal-confirm #modalConfirm [content]="confirmMessage" (onCloseModal)="onCloseModalConfirm($event)"></app-modal-confirm>