<div class="bg-error-service">
    <div class="content">
        <div class="row">
            <div class="col-6">
                <div class="row d-flex">
                    <div class="col-5 offset-4">
                        <img src="assets/images/error-500.svg">
                    </div>
                    <div class="col-3">
                        <span class="p-semibold mb-2 error">500</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="color">

                </div>
            </div>
            <div class="col-6">
                <div class="row d-block">
                <h2 class="color-white mb-2">Error de servidor</h2>
                <h6 class="color-white mb-4">AQUI NO HAY NADA!</h6>
                <h6 class="color-white mb-4">El servidor no ha sido encontrado. Verifica el cable de red o tu conexión a internet</h6>
                <button class="btn btn-error">Salir de aquí</button>
                </div>
            </div>
        </div>
    </div>
</div>
