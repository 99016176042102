import { Component, OnInit,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';

import { Router } from '@angular/router';
import { ApirestService } from '@core/services/apirest.service';
import { SingletonService } from '@core/services/singleton.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import Swal from 'sweetalert2';
/* import * as moment from 'moment';
import 'moment/locale/es'; */
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  showIndicator = true;
  activeSection = 1;
  form: FormGroup;
  visibilidad= false;
  formContact: FormGroup;
  public markers = [];
  lat = 0;
  long = 0;
  zoom:number = 12;
  user : any;
  roleId = 0;
  moreDetail = false;
  deviceId = 0;
  device = {
    device_id : 0,
    name : '',
    capture_log : {
      date: '',
      created_at:'',
      elevation: 0,
      humidity: 0,
      ica: 0,
      pressure: 0,
      temperature: 0,
      description : '',
      color : '',
      icono: '',
      url_emoticon : '',
      name : '',
      name_device:  ''
    },
    more_detail : false
  }
  path = 'admin';
  showAirScale =  false;
  deviceSelect = false;

  constructor(
    public service: ApirestService,
    public singleton : SingletonService,
    public router: Router,
    private formBuilder: FormBuilder,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.getLocation();
    this.buildForm();
   }

  ngOnInit(): void {
    setInterval (() => {
      let datos = {
        consulta:true
      };
      this.service.queryPost(`get-data-historic-by-device`, datos)
      .subscribe(
        (response:any) =>
        {
         
          this.markers = response.result; 
          this.device = this.markers.find(x=>x.name == this.capture_log.name_device);
          this.capture_log.created_at = moment(this.capture_log.created_at).format("YYYY-MM-DD HH:mm");          
        },
        (err:any) =>
        {
          console.error(err);
        }
      )
   }, 60000);
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      search: [''],
    });
    this.formContact = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      subject: ['', [Validators.required]],
      drafting: ['', [Validators.required]],
    });
  }

  capture_log = {
    elevation: 0,
    humidity: 0,
    created_at:'',
    ica: 0,
    pressure: 0,
    temperature: 0,
    description : '',
    color : '',
    icono: '',
    url_emoticon : '',
    name : '',
    name_device: ''
  }
 
  initMap() {
      let datos = {
        consulta:true
      };
      this.service.queryPost(`get-data-historic-by-device`, datos)
      .subscribe(
        (response:any) =>
        {
          this.markers = response.result; 
          this.capture_log = response.capture_log;
          this.singleton.updateLoading(false);
          Swal.fire({
            title: 'Seleccione una estacion para consultar su informacion',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
        },
        (err:any) =>
        {
          console.error(err);

        }
      )
  }

  markerClicked(deviceId: number)
  {
    this.device = this.markers.find(x=>x.device_id == deviceId);
    this.capture_log = this.device.capture_log;
    if (this.capture_log.created_at.includes('Z')) this.capture_log.created_at = moment(this.capture_log.created_at).tz('UTC').format("YYYY-MM-DD HH:mm");
    this.deviceId = this.device.device_id;
    this.deviceSelect = true;
    this.visibilidad = true;
    
  }

  showDetail()
  {
    this.router.navigate([`${this.path}/equipos/detalle/${this.deviceId}`]);

  }
  contact(event: Event) {
    event.preventDefault();
		if (this.formContact.invalid) {
			this.formContact.markAllAsTouched();
			return;
		}

		let values = Object.assign({}, this.formContact.value);
		let body = new FormData();
		Object.keys(values).forEach(key => body.append(key, values[key]));

		this.service.queryPostRegular(`send-email`, body).subscribe(
			(response: any) => {
				//-- Close Loading
				if (response.success) {
				this.singleton.showAlert({type: 'success', content: response.message});
				} else {
				this.singleton.showAlert({type: 'error', content: response.message});
				}
			},
			(err: any) => {
			}
		);
  }
  scrollTo(id) {
    const section = document.getElementById(id);
    section.scrollIntoView({behavior: 'smooth', block: 'start'});
    if (document.getElementById(id)) {
      section.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

  getShowAirScale(value): void {
    this.showAirScale = value;
  }

  getPosition(): Promise<any> 
  {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });
  }

  getLocation() 
  {
    navigator.geolocation.getCurrentPosition(
      resp => 
      {
        this.lat = resp.coords.latitude;
        this.long = resp.coords.longitude;
        
        this.initMap();
      },
      err => 
      {
        
        this.lat = 5.067427;
        this.long = -75.51935;

        this.initMap();
        
      }
    );

    
  }

}
