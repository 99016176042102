import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Error500Component } from '@shared/components/error500/error500.component';
import { Error404Component } from '@shared/components/error404/error404.component';
import { LandingComponent } from '@shared/components/landing/landing.component';
import {MapDisplayComponent} from '@shared/components/mapDisplay/map.display.component';
const routes: Routes = [
	{ path: '', pathMatch: 'full', component:LandingComponent },
	{ path: 'visualizador/:username', pathMatch: 'full', component:MapDisplayComponent },
	{ path: '', redirectTo: '/index', pathMatch: 'full' },
	// { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
	// { 
	// 	path: '', 
	// 	loadChildren: () => import('./home/home.module').then(m => m.HomeModule) 
	// },
	{ 
		path: 'auth', 
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) 
	},
	{ 
		path: 'admin', 
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
	},
	{ 
		path: 'cliente', 
		loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) 
	},
	{ 
		path: 'tecnico', 
		loadChildren: () => import('./technical/technical.module').then(m => m.TechnicalModule) 
	},
	{ 
		path: 'analista', 
		loadChildren: () => import('./analyst/analyst.module').then(m => m.AnalystModule) 
	},

	//-- Error 500 page
	{ path: 'error-500', component: Error500Component },
	//-- Not found page
	{ path: '**', component: Error404Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
