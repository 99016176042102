import { Component, OnInit } from '@angular/core';
import {SingletonService} from "@core/services/singleton.service";
import {ApirestService} from "@core/services/apirest.service";

@Component({
  selector: 'app-drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrls: ['./drawer-header.component.scss']
})
export class DrawerHeaderComponent implements OnInit {

  public user: any;
  constructor(public singleton: SingletonService,
              public service: ApirestService) { }

  ngOnInit(): void {
    this.user = this.singleton.getSessionUser();
  }

  toggleMenu(item, id): void {
    const drawer = document.getElementById('drawer-' + item);
    drawer.classList.toggle('active');
    this.goTo(id)
  }

  stopPropagation(event): void {
    event.stopPropagation();
  }

  goTo(id): void {
    const section = document.getElementById(id);
    if (document.getElementById(id)) {
      section.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }

}
