import { Component, OnInit } from '@angular/core';
import { ApirestService } from '@core/services/apirest.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  countCompanies = 0;
  countDevicesActive = 0;
  countUsers = 0;
  countCaptureLog = 0;

  constructor(
    private service: ApirestService,
  ) { }

  ngOnInit(): void {

    this.getCountCompanies();
    this.getCountDevicesActive();
    this.getCountUsers();
    this.getCountCaptureLog();

  }

  getCountCompanies()
  {
    this.service.queryGet(`companies`)
    .subscribe(
      (response:any) => {
        let result = response;

        this.countCompanies = result.count;
        
      },
      (err:any) => {
        console.log(err);
        
      }
    )
  }

  getCountDevicesActive()
  {
    this.service.queryGet(`devices`)
    .subscribe(
      (response:any) => {
        let result = response;

        this.countDevicesActive = result.count;
        
      },
      (err:any) => {
        console.log(err);
        
      }
    )
  }

  getCountUsers()
  {
    this.service.queryGet(`users`)
    .subscribe(
      (response:any) => {
        let result = response;

        this.countUsers = result.count;
        
      },
      (err:any) => {
        console.log(err);
        
      }
    )
  }

  getCountCaptureLog()
  {
    this.service.queryGet(`count-capture-today`)
    .subscribe(
      (response:any) => {
        let result = response;

        this.countCaptureLog = result.count;
        
      },
      (err:any) => {
        console.log(err);
        
      }
    )
  }

}
