<section>
    <nav class="navbar navbar-expand-lg py-3 fixed-top d-lg-flex d-none">
        <div class="collapse navbar-collapse navbar-gestam justify-content-between" id="navbarColor03">
            <a class="header-img border-0 text-decoration-none" href="/">
                <img class="navbar-brand navbar-img" src="assets/images/admin/logo-menu.svg">
            </a>
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <a class="nav-link border-0 font-weight-bold pointer" (click)="scrollTo('map')">MAPA <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link border-0 font-weight-bold pointer" (click)="scrollTo('know_us')">CONÓCENOS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link border-0 font-weight-bold pointer" (click)="scrollTo('partners')">COLABORADORES</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link border-0 font-weight-bold pointer" (click)="scrollTo('contact')">CONTACTO</a>
                </li>
            </ul>
            <ul class="navbar-nav">
              <li class="nav-item ml-5">
                <a class="nav-link color-white border-0 d-flex align-items-center font-weight-bold" href="/auth/login">INGRESA<i class="las la-user pl-2"></i></a>
              </li>
            </ul>

        </div>
    </nav>
    <app-drawer-header></app-drawer-header>
</section>
<section>
    <div class="back overflow-hidden" >
        <div class="mt-sm-0 container_map h-100" *ngIf="capture_log.name !== 'Apagado'" >
            <div *ngIf="visibilidad">
                <div class="card medidor_landing p-0 m-md-0 border-0" [ngStyle]="{'background-color': capture_log.color}" *ngIf="!showAirScale" >
                    <div class="row mx-0 py-4 px-0 p-sm-5" >
                        <div class="col-9 col-md-9">
                            <div class="container-fluid mb-sm-5">
                                <div class="row m-0 p-0">
                                    <div class="d-flex flex-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                                        <div class="cloud_map d-flex position-relative align-items-center justify-content-center mr-3 mb-3 mb-sm-0">
                                            <div class="text_cloud">
                                                <p class="semibold" *ngIf="capture_log.ica">{{capture_log.ica | number : '1.0-0'}}</p>
                                                <p class="semibold" *ngIf="!capture_log.ica">0</p>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="text_aqui_tittle">
                                                <p class="semibold">AQI</p>
                                            </div>
                                            <div class="text_aqui_sub_tittle">
                                                <p class="semibold">Indice de Calidad de Aire <br> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column pt-4">
                                    <div class="col px-0 text_quality_tittle pb-4 pt-5 pb-sm-0 pt-sm-0">
                                        <p class="semibold">La calidad del aire en<br> {{capture_log.name_device}} el día {{capture_log.created_at }} es</p>
                                    </div>
                                    
                                    <div class="col px-0 text_quality_face pb-3">
                                        <p class="semibold">{{capture_log.name}}</p>
                                    </div>
                                    <div class="col px-0 p_description">
                                        <p class="semibold">{{capture_log.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-md-3 p-sm-0 pl-0">
                            <div class="ul_map">
                                <ul class="align-items-end align-items-sm-end d-flex flex-column text-center">
                                    <li class="align-items-center w-100"> <i class="las la-tint fs-big"></i>
                                        <small for="">Hume.</small>
                                        <label class="semibold ">{{capture_log.humidity}} %</label>
                                    </li>
                                    <li class="align-items-center w-100"> <i class="las la-thermometer-half fs-big"></i>
                                        <small for="">Temp.</small>
                                        <label class="semibold">{{capture_log.temperature}} °C</label>
                                    </li>
                                    <li class="align-items-center w-100"> <i class="las la-cloud-sun-rain fs-big"></i>
                                        <small for="">Presión</small>
                                        <label class="semibold">{{capture_log.pressure}} Hpa</label>
                                    </li>
                                    <!-- <li class="align-items-center w-100" *ngIf="deviceSelect"> 
                                        <i class="las la-chart-bar fs-normal chart_icon pointer" (click)="showAirScale = true"></i>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <app-air-scale *ngIf="showAirScale" [showAirScale]="showAirScale" [captureLog]="capture_log"  [device]="device" (changeShowAirScale)="getShowAirScale($event)"></app-air-scale>
                <div class="col face_map_landing pt-2" *ngIf="!showAirScale">
                    <img [src]="capture_log.url_emoticon" alt="">
                </div>
            </div>
            <div class="map" id="map">
                <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom" [mapTypeId]="'roadmap'">
                    <agm-marker *ngFor="let marker of markers;" [latitude]="marker.lat" [longitude]="marker.lon" [iconUrl]='{"url": marker.capture_log.icono,"scaledSize": {"height": 40, "width": 40}}' (markerClick)="markerClicked(marker.device_id)" >
                    </agm-marker>
                </agm-map>
            </div>
        </div>
        <div class="mt-sm-0 container_map h-100" *ngIf="capture_log.name == 'Apagado'">
            <div class="card medidor_landing content-gray p-0 m-md-0 border-0" [ngStyle]="{'background-color': capture_log.color}" *ngIf="!showAirScale">
                <div class="row mx-0 py-4 px-0 p-sm-5" >
                    <div class="col-12 col-md-12 content-gray">
                        <div class="container-fluid mb-sm-5">
                            <!-- <div class="row m-0 p-0">
                                <div class="d-flex flex-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                                    <div class="cloud_map d-flex position-relative align-items-center justify-content-center mr-3 mb-3 mb-sm-0">
                                        <div class="text_cloud">
                                            <p class="semibold" *ngIf="capture_log.ica">{{capture_log.ica | number : '1.0-0'}}</p>
                                            <p class="semibold" *ngIf="!capture_log.ica">0</p>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div class="text_aqui_tittle">
                                            <p class="semibold">AQI</p>
                                        </div>
                                        <div class="text_aqui_sub_tittle">
                                            <p class="semibold">Indice de Calidad de Aire <br> </p>
                                        </div>
                                    </div>
                                </div>

                            </div> -->
                            <div class="d-flex flex-column pt-4">
                                <!-- <div class="col px-0 text_quality_tittle pb-4 pt-5 pb-sm-0 pt-sm-0">
                                    <p class="semibold">La calidad del aire en<br> {{capture_log.name_device}} es</p>
                                </div>
                                <div class="col px-0 text_quality_face pb-3">
                                    <p class="semibold">{{capture_log.name}}</p>

                                </div> -->
                                <div class="col px-0 p_description">
                                    <h5 class="semibold">{{capture_log.description}}</h5>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="px-4 text_quality_p col-9 col-sm-9">
                                    <p class="semibold">{{capture_log.description}}</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="col-3 col-md-3 p-sm-0 pl-0">
                        <div class="ul_map">
                            <ul class="align-items-end align-items-sm-end d-flex flex-column">
                                <li class="align-items-center"> <i class="las la-tint fs-big"></i>
                                    <small for="">Hume.</small>
                                    <label class="semibold ">{{capture_log.humidity}}</label>
                                </li>
                                <li class="align-items-center"> <i class="las la-thermometer-half fs-big"></i>
                                    <small for="">Temp.</small>
                                    <label class="semibold">{{capture_log.temperature}}</label>
                                </li>
                                <li class="align-items-center"> <i class="las la-mountain fs-big"></i>
                                    <small for="">Altura</small>
                                    <label class="semibold">{{capture_log.elevation}}</label>
                                </li>
                                <li class="align-items-center"> <i class="las la-cloud-sun-rain fs-big"></i>
                                    <small for="">Presión</small>
                                    <label class="semibold">{{capture_log.pressure}}</label>
                                </li>
                                <li class="align-items-center" *ngIf="deviceSelect"> 
                                    <i class="las la-chart-bar fs-normal chart_icon pointer" (click)="showAirScale = true"></i>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
            <app-air-scale *ngIf="showAirScale" [showAirScale]="showAirScale" [captureLog]="capture_log"  [device]="device" (changeShowAirScale)="getShowAirScale($event)"></app-air-scale>
            <div class="col face_map_landing pt-2" *ngIf="!showAirScale">
                <img [src]="capture_log.url_emoticon" alt="">
            </div>
            <div class="map" id="map">
                <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom" [mapTypeId]="'roadmap'">
                    <agm-marker *ngFor="let marker of markers;" [latitude]="marker.lat" [longitude]="marker.lon" [iconUrl]="marker.icon" (markerClick)="markerClicked(marker.id)">
                    </agm-marker>
                </agm-map>
            </div>
        </div>

        <div class="data h-100 p-0 m-0 overflow-hidden" id="know_us">
            <div class="">
                <img src="../../../../assets/images/smock.png" alt="" srcset="" class="smock position-absolute">

            </div>
            <div class="container container-know-us">
                <div class="row align-items-center h-100">
                    <div class="col-6 d-none d-md-block"></div>
                    <div class="col-12 col-md-6 text color-white mb-5 mt-5 mt-md-0 mr-0 ml-auto p-4 fs-small">
                        <p class="monitor">Monitoreo Fisicoquímico y análisis de</p>
                        <!-- <p class="semibold">Datos</p> -->
                        <h3 class="pt-2 fs-bigger">Datos</h3>
                        <p class="light color-primary pt-2 fs-small description">Se lleva a cabo el monitoreo permanente de diferentes variables, desarrollando las tecnicas apropiadas para la determinacion y el analisis fisicoquimico de los contaminantes presentes en las matrices medioambientales. Siempre de la mano de la investigacion y la innovacion, se busca promover la identificacion y evaluacion del impacto generado por los contaminantes del ambiente, para disminuir los efectos nocivos y optimizar el uso adecuado de los rescursos naturales garantizando el desarrollo de una sociedad sostenible</p>
                    </div>
                </div>
            </div>

            <div class="">
                <img src="../../../../assets/images/particula_3.svg" alt="" srcset="" class="particle">
            </div>
            <!-- <div class="">
                <img src="../../../../assets/images/particula.svg" alt="" srcset="" class="circle">
            </div> -->

        </div>

        <app-partners></app-partners>
        <app-particle></app-particle>

        <div class="city p-0 m-0">
            <div class="">
                <img src="../../../../assets/images/manizales.svg" alt="" srcset="" class="circle">
            </div>

            <div class="section-form row" id="contact">
                <div class="container ">
                    <div class="row justify-content-center mx-0">
                        <div class="col-12 col-sm-6">
                            <div class="contact_text d-flex flex-column justify-content-center h-100 pb-4">
                                <label class="about-title txt-black mb-2">Contacto</label>
                                <p class="mb-2">Escríbenos y Suscríbete a nuestro portal informativo para estar actualizado.
                                </p>
                            </div>

                        </div>
                        <div class="col-12 col-sm-6 px-4">
                            <form [formGroup]="formContact" class="inn-form" (ngSubmit)="contact($event)" novalidate>
                                <div class="form-group w-100">
                                    <label for="formGroupExampleInput">E-MAIL</label>
                                    <input type="text" class="form-control f-subtitle" id="formGroupExampleInput" placeholder="Máximo 200 caracteres" formControlName="email">
                                    <app-validation-error [form]="formContact" [field]="'email'"></app-validation-error>

                                </div>
                                <div class="form-group w-100">
                                    <label for="formGroupExampleInput2">ASUNTO</label>
                                    <input type="text" class="form-control f-subtitle" id="formGroupExampleInput2" placeholder="Máximo 200 caracteres" formControlName="subject">
                                    <app-validation-error [form]="formContact" [field]="'subject'"></app-validation-error>

                                </div>
                                <div class="form-group w-100 mb-4">
                                    <label for="formGroupExampleInput2">REDACCIÓN</label>
                                    <textarea class="form-control f-subtitle" aria-label="With textarea" placeholder="Máximo 5000 caracteres" style="height:300px;" formControlName="drafting"></textarea>
                                    <app-validation-error [form]="formContact" [field]="'drafting'"></app-validation-error>

                                </div>
                                <div class="form-group w-100 pb-5 mt-1">
                                    <button class="btn btn-primary btn-send-form f-subtitle">
                                        Enviar y Suscribirse
                                    </button>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>

            </div>

        </div>
        <app-footer></app-footer>
    </div>
</section>

<!-- <footer class="footer d-flex text-left fs-small sticky-bottom">
    <div class="img">
        <img src="../../../../assets/images/simbolo_footer.svg" alt="" srcset="">
    </div>
    <div class="col fs-small">
        <h6 class="pb-4">Responsabilidad</h6>
        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
    </div>
    <div class="col-2 fs-small d-flex flex-column">
        <h6 class="pb-4">Síguenos</h6>
        <div class="col m-0 p-0">
            <i class="lab la-facebook pr-2"></i>
            <label for="" class="m-0">Gestam Oficial</label>
        </div>
        <div class="col m-0 p-0">
            <i class="lab la-youtube pr-2"></i>
            <label for="" class="m-0">Gestam Oficial</label>
        </div>
        <div class="col m-0 p-0">
            <i class="lab la-twitter pr-2"></i>
            <label for="" class="m-0">@gestamoficial</label>
        </div>
        <div class="col m-0 p-0">
            <i class="lab la-instagram pr-2"></i>
            <label for="" class="m-0">@gestamoficial</label>
        </div>
    </div>
    <div class="col fs-small">

        <div class="col m-0 p-0">
            <i class="las la-city pr-2"></i>
            <label for="" class="m-0">Carrera 23 No. 45-78 Santander Manizales</label>
        </div>
        <div class="col m-0 p-0">
            <i class="lab la-whatsapp pr-2"></i>
            <label for="" class="m-0">300 098 90 65</label>
        </div>
        <div class="col m-0 p-0">

            <label for="" class="m-0">Todos los derechos Reservados - Gestam 2022©</label>
        </div>

    </div>
</footer> -->
